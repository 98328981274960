<style lang="scss">
  @import './InfoModal.scss';
</style>

<template>
  <div v-if="show_modal" class="info-modal-container">
    <div class="modal-backdrop" v-on:click="toggleModal(false)"></div>
    <div class="modal">
      <div class="modal-header">
        <a v-on:click="toggleModal(false)" class="close-modal-btn">
          Chiudi 
          <svg width="24" height="24" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0595 3.05994L18.9395 0.939941L10.9995 8.87994L3.05945 0.939941L0.939453 3.05994L8.87945 10.9999L0.939453 18.9399L3.05945 21.0599L10.9995 13.1199L18.9395 21.0599L21.0595 18.9399L13.1195 10.9999L21.0595 3.05994Z" />
          </svg>
        </a>
      </div>
      <div class="modal-image" v-if="modal_image !== ''">
        <img v-bind:src="modal_image" />
      </div>
      <div class="modal-body">
        <h3 v-if="modal_headtitle !== ''">{{modal_headtitle}}</h3>
        <h1 v-if="modal_title !== ''">{{modal_title}}</h1>
        <h2 v-if="modal_summary !== ''">{{modal_summary}}</h2>
        <hr v-if="modal_additional.modal_text !== ''"/>
        <p v-if="modal_additional.modal_text !== ''" v-html="modal_additional.modal_text"></p>
        <div class="button-container" v-if="modal_additional.modal_buttons.length > 0">
          <button class="primary" v-for="button in modal_additional.modal_buttons" v-on:click="toggleButton(button, true)" v-bind:data-item="button['@type']" v-bind:key="button['@type']">
            {{ button.label }}
          </button>
        </div>
        <hr v-if="modal_text !== ''"/>
        <p v-if="modal_text !== ''" v-html="modal_text"></p>
        <div class="button-container" v-if="modal_buttons.length > 0">
          <button v-for="button in modal_buttons" v-on:click="toggleButton(button)" v-bind:key="button['@type']">
            <i class="icon-share" />
            {{ button.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoModal',
  data: () => ({
    modal_image: ``,
    modal_headtitle: ``,
    modal_title: ``,
    modal_summary: ``,
    modal_text: ``,
    modal_additional: {
      modal_text: ``,
      modal_buttons: [],
    },
    modal_buttons: [],
    show_modal : false,
  }),
  methods: {
    toggleModal(newState = null){
      if (newState === null){
        newState = !this.show_modal;
      }
      this.show_modal = newState;
      this.sendResult = '';
      this.$emit(newState ? 'modalShown' : 'modalHidden');
    },
    toggleButton(buttonInfo, toggleModal = false){
      this.toggleModal(toggleModal);
      this.$emit('buttonPressed', JSON.parse(JSON.stringify(buttonInfo)))
    },
    doLogin() {
      this.toggleModal(false);
      this.$emit('doLogin');
    }
  }
}
</script>